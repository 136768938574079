.hero-section {
    position: relative;
    height: 78vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('https://imagesuploadforwebsite.s3.amazonaws.com/WIN_20240607_10_19_56_Pro.mp4') no-repeat center center/cover;
}

.hero-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.text-section {
    position: relative;
    z-index: 2;
    width: 60%;
    padding: 0 32px;
    color: white;
    text-align: center;
}

.text-headline {
    margin-bottom: 12px;
    color: white;
    font-family: 'Rubik', sans-serif;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: .7px;
}

.text-title {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
}

.text-description {
    margin: 32px 0;
    color: white;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .7px;
    line-height: 1.6rem;
}

.text-appointment-btn {
    padding: 14px 20px;
    color: white;
    border: 1px solid transparent;
    border-radius: 28px;
    background-color: #15803D;
    /* Darker green for better contrast */
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
}

.text-appointment-btn:hover {
    color: white;
    background-color: #16A34A;
    /* Slightly lighter green for hover */
    border: 1px solid #15803D;
}


.text-stats {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 48px;
}

.text-stats-container {
    text-align: center;
}

.text-stats-container>p {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    letter-spacing: .7px;
}

.text-stats-container p:first-child {
    margin-bottom: 8px;
    color: #00000;
    font-family: 'Rubik', sans-serif;
    font-size: 32px;
    font-weight: bold;
}

/* Cards Section */
.cards-section {
    /* padding: 20px; */
    background-color: #f5f5f5;
}

.cards-wrapper {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    gap: 15px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.card-item {
    flex: 0 0 250px;
    /* Smaller size for cards */
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    scroll-snap-align: start;
    transition: transform 0.3s ease;
}

.card-item:hover {
    transform: scale(1.05);
}

.card-image {
    width: 100%;
    height: 120px;
    /* Adjust image height */
    object-fit: cover;
}

.card-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.card-content h2 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.card-content p {
    font-size: 14px;
    color: #666;
}

.card-content button {
    margin-top: 15px;
    padding: 10px;
    background-color: #1A8EFD;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.card-content button:hover {
    background-color: #007acc;
}

@media screen and (max-width: 900px) {
    .card-item {
        flex: 0 0 90%;
    }
}

/* .scroll-up {
    width: 45px;
    height: 45px;
    display: none;
    align-items: center;
    justify-content: center;
    color: white;
    border: 3px solid white;
    border-radius: 50%;
    background-color: #1A8EFD;
    position: fixed;
    bottom: 50px;
    right: 50px;
    font-size: 24px;
    z-index: 20;
    cursor: pointer;
}  
.show-scroll {
    display: flex;
} */

@media screen and (max-width: 900px) {
    .text-section {
        width: 100%;
        padding: 0;
    }

    .text-title,
    .text-description {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .text-headline {
        font-size: 16px;
    }

    .text-title {
        font-size: 28px;
    }

    .text-description {
        font-size: 16px;
    }

    .text-appointment-btn {
        font-size: 16px;
    }

    .text-stats {
        gap: 18px;
    }

    .text-stats-container>p {
        font-size: 10px;
    }

    .text-stats-container p:first-child {
        font-size: 22px;
    }

    .scroll-up {
        bottom: 32px;
        right: 32px;
    }
}