.tooltip-container:hover .tooltip {
  top: -150px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.icon:hover .layer {
  transform: rotate(-35deg) skew(20deg);
}

.icon .layer span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #000;
  border-radius: 5px;
  transition: all 0.3s;
}

.icon:hover .layer span:nth-child(1) {
  opacity: 0.2;
}

.icon:hover .layer span:nth-child(2) {
  opacity: 0.4;
  transform: translate(5px, -5px);
}

.icon:hover .layer span:nth-child(3) {
  opacity: 0.6;
  transform: translate(10px, -10px);
}

.icon:hover .layer span:nth-child(4) {
  opacity: 0.8;
  transform: translate(15px, -15px);
}

.icon:hover .layer span:nth-child(5) {
  opacity: 1;
  transform: translate(20px, -20px);
}

.icon .text {
  position: absolute;
  left: 50%;
  bottom: -5px;
  opacity: 0;
  transform: translateX(-50%);
  transition: bottom 0.3s ease, opacity 0.3s ease;
}

.icon:hover .text {
  bottom: -35px;
  opacity: 1;
}

.icon:hover .layer span.fab {
  font-size: 30px;
  line-height: 64px;
  text-align: center;
  fill: #1da1f2;
  background: #fff;
}
